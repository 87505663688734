import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";

const UserSettlements = () => {
  const [usersettlementsi, setUserSettlements] = useState([]);
  const [useryId, setUserYId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserSettlements();
  }, []);

  const fetchUserSettlements = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUserSettlements(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching User Settlements:", error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownload = (endpoint, fileType) => {
    const token = localStorage.getItem("token");
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `usersettlementreport_${new Date().toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.log(error);
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  const handleDownloadWithuserId = (fileType) => {
    const token = localStorage.getItem("token");

    if (!useryId) {
      toast.error("Please enter a valid User ID");
      return;
    }

    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/tenantsettlement/${useryId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { format: fileType },
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `usersettlementreport_${new Date().toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.log(error);
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error(error.response.data.response);
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">User Settlements</h1>
        {/* Download buttons */}
        {/* Flex container to separate original and new buttons */}
        <div className="flex justify-between items-center w-full max-w-4xl mb-4">
          {/* Original download buttons on the left */}
          <div className="flex space-x-2">
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/tenantsettlementme",
                  "csv"
                )
              }
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              CSV
            </button>
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/admin/settlementreport/tenantsettlementme",
                  "pdf"
                )
              }
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              PDF
            </button>
          </div>

          {/* Input box and new download buttons on the right */}
          <div className="flex items-center space-x-4">
            <div className="flex-grow">
              <label
                htmlFor="useryId"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                User ID
              </label>
              <input
                type="text"
                id="useryId"
                value={useryId}
                onChange={(e) => setUserYId(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() => handleDownloadWithuserId("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
              >
                <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
                CSV with User ID
              </button>
              <button
                onClick={() => handleDownloadWithuserId("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm flex items-center"
              >
                <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
                PDF with User ID
              </button>
            </div>
          </div>
        </div>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Settlement Id</th>
              <th className="px-4 py-2">User ID</th>
              <th className="px-4 py-2">Total Amount Paid</th>
              <th className="px-4 py-2">Transaction cost</th>
              <th className="px-4 py-2">Our Reference</th>
              <th className="px-4 py-2">Transaction Reference</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">OTP</th>
              <th className="px-4 py-2">Attempts</th>
              <th className="px-4 py-2">Created On</th>
            </tr>
          </thead>
          <tbody>
            {usersettlementsi.map((usersettlementsi) => (
              <tr key={usersettlementsi.settlement_id}>
                <td className="border px-4 py-2">
                  {usersettlementsi.settlement_id}
                </td>
                <td className="border px-4 py-2">{usersettlementsi.user_id}</td>
                <td className="border px-4 py-2">
                  {usersettlementsi.total_amount_paid}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.transactioncost}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.reference}
                </td>
                <td className="border px-4 py-2">
                  {usersettlementsi.paymentreference}
                </td>
                <td className="border px-4 py-2">{usersettlementsi.status}</td>
                <td className="border px-4 py-2">{usersettlementsi.otp}</td>
                <td className="border px-4 py-2">
                  {usersettlementsi.attempts}
                </td>
                <td className="border px-4 py-2">
                  {new Date(usersettlementsi.created_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AuthGuard>
  );
};

export default UserSettlements;
